<template>
  <div>
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <h3>個人プロファイル</h3>
      <div class="personal">
        <el-form-item label="フルネーム" prop="full_name">
          <el-input v-model="ruleForm.full_name"></el-input>
        </el-form-item>
      </div>
      <br />
      <h3>見積書プロファイル</h3>
      <div class="estimate">
        <el-form-item label="支払い条件" prop="payment_conditions">
          <el-input
            type="textarea"
            v-model="ruleForm.payment_conditions"
          ></el-input>
        </el-form-item>
        <el-form-item label="その他費用" prop="other">
          <el-input type="textarea" v-model="ruleForm.other"></el-input>
        </el-form-item>
        <el-form-item label="補足事項" prop="supplement">
          <el-input type="textarea" v-model="ruleForm.supplement"></el-input>
        </el-form-item>
        <el-form-item label="ステータス" prop="status">
          <el-checkbox-group v-model="ruleForm.status" :min="1">
            <el-checkbox label="1" name="status">発注</el-checkbox>
            <el-checkbox label="2" name="status">納品</el-checkbox>
            <el-checkbox label="3" name="status">検収</el-checkbox>
            <el-checkbox label="4" name="status">請求</el-checkbox>
            <el-checkbox label="5" name="status">失注</el-checkbox>
            <el-checkbox label="0" name="status">見積</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <br />
      <h3>請求書プロファイル</h3>
      <div class="invoice">
        <el-form-item label="※(注)" prop="note">
          <el-input type="textarea" v-model="ruleForm.note"></el-input>
        </el-form-item>
        <el-form-item label="区分" prop="distinguish">
          <el-checkbox-group v-model="ruleForm.distinguish" :min="1">
            <el-checkbox label="0" name="distinguish">1:定期</el-checkbox>
            <el-checkbox label="1" name="distinguish">2:年一</el-checkbox>
            <el-checkbox label="2" name="distinguish">3:スポット</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="分類" prop="classification">
          <el-checkbox-group v-model="ruleForm.classification" :min="1">
            <el-checkbox label="0" name="classification">A:開発</el-checkbox>
            <el-checkbox label="1" name="classification"
              >B:リセール</el-checkbox
            >
            <el-checkbox label="2" name="classification">C:保守</el-checkbox>
            <el-checkbox label="3" name="classification">D:工事</el-checkbox>
            <el-checkbox label="4" name="classification">E:その他</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="btn">
        <el-form-item>
          <el-button
            type="warning"
            @click="cancelForm()"
            icon="el-icon-document-delete"
            plain
            >閉じる</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            icon="el-icon-document-checked"
            plain
            >登録・更新</el-button
          >
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getUser, putUser } from "api/user";
import { Message } from "element-ui";
export default {
  name: "Settings",
  data() {
    return {
      ruleForm: {
        full_name: "",
        payment_conditions: "",
        other: "",
        supplement: "",
        status: [],
        note: "",
        distinguish: [],
        classification: []
      }
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      let values = {};
      values["user_name"] = localStorage.getItem("user_name");
      getUser(values)
        .then(res => {
          this.ruleForm.full_name = res.data.data[0].full_name;
          this.ruleForm.payment_conditions =
            res.data.data[0].payment_conditions;
          this.ruleForm.other = res.data.data[0].other;
          this.ruleForm.supplement = res.data.data[0].supplement;
          this.ruleForm.note = res.data.data[0].note;
          if (res.data.data[0].status == "") {
            this.ruleForm.status = ["0"];
          } else {
            this.ruleForm.status = res.data.data[0].status.split(",");
          }
          if (res.data.data[0].distinguish == "") {
            this.ruleForm.distinguish = ["0"];
          } else {
            this.ruleForm.distinguish = res.data.data[0].distinguish.split(",");
          }
          if (res.data.data[0].classification == "") {
            this.ruleForm.classification = ["0"];
          } else {
            this.ruleForm.classification = res.data.data[0].classification.split(
              ","
            );
          }
        })
        .catch(err => {
          console.log("2022-06-07 16:16:43 🏍☆…☆…☆…☆…☆…☆…:", err);
        });
    },
    putUser() {
      let values = {};
      values["full_name"] = this.ruleForm.full_name;
      values["user_name"] = localStorage.getItem("user_name");
      values["payment_conditions"] = this.ruleForm.payment_conditions;
      values["other"] = this.ruleForm.other;
      values["supplement"] = this.ruleForm.supplement;
      values["status"] = this.ruleForm.status.toString();
      values["note"] = this.ruleForm.note;
      values["distinguish"] = this.ruleForm.distinguish.toString();
      values["classification"] = this.ruleForm.classification.toString();
      values["modified_by"] = localStorage.getItem("full_name");
      putUser(values)
        .then(res => {
          this.$emit("cancel");
          Message({
            showClose: true,
            message: res.data.message,
            type: "success"
          });
        })
        .catch(err => {
          console.log("2022-06-07 16:16:43 🏍☆…☆…☆…☆…☆…☆…:", err);
        });
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.putUser();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancelForm() {
      this.$emit("cancel");
    }
  }
};
</script>

<style scoped>
.personal {
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  border: 2px solid;
  border-radius: 5px;
}
.estimate,
.order,
.delivery,
.invoice {
  box-sizing: border-box;
  position: relative;
  margin: 10px 0;
  padding: 10px;
  border: 2px solid;
  border-radius: 5px;
}
.btn {
  box-sizing: border-box;
  position: relative;
  left: -70px;
}
</style>