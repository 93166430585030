import { request } from 'network/request'

export function getUser(parameter) {
  return request({
    url: '/login/user/' + parameter.user_name,
    method: 'get',
    data: parameter,
  })
}

export function putUser(parameter) {
  return request({
    url: '/login/user/' + parameter.user_name,
    method: 'put',
    data: parameter,
  })
}


export function Signup(parameter) {
  return request({
    url: '/login/signup',
    method: 'post',
    data: parameter
  })
}

export function putUserData(parameter) {
  return request({
    url: '/login/signup',
    method: 'put',
    data: parameter
  })
}

export function Active(parameter) {
  return request({
    url: '/login/signup',
    method: 'put',
    data: parameter
  })
}

export function ResetPassword(parameter) {
  return request({
    url: '/login/resetpassword/' + parameter.email,
    method: 'put',
    data: parameter
  })
}


export function Signin(parameter) {
  return request({
    url: '/login',
    method: 'post',
    data: parameter
  })
}

export function checkEmail(parameter) {
  return request({
    url: '/login/checkemail/' + parameter.email,
    method: 'get',
    data: parameter
  })
}


export function checkRandNum(parameter) {
  return request({
    url: '/login/randnum',
    method: 'get',
    params: parameter
  })
}