<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div :class="changeLogo" />
      <a-menu theme="light" mode="inline" :default-selected-keys="['1']">
        <template v-for="item in list">
          <a-menu-item v-if="!item.children" :key="item.key">
            <router-link :to="item.url">
              <a-icon :type="item.icon" />
              <span>{{ item.title }}</span>
            </router-link>
          </a-menu-item>
          <a-sub-menu v-else :key="item.key + 1">
            <template #title>
              <a-icon :type="item.icon" />
              <span>{{ item.title }}</span>
            </template>
            <template v-for="citem in item.children">
              <a-menu-item :key="citem.key">
                <router-link :to="citem.url">
                  <a-icon :type="citem.icon" />
                  <span>{{ citem.title }}</span>
                </router-link>
              </a-menu-item>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header>
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <span class="user">
          <el-dropdown
            @command="handleCommand"
            trigger="click"
            placement="bottom-start"
          >
            <a-icon type="user" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logOut">
                <a-icon type="logout" />Logout</el-dropdown-item
              >
              <el-dropdown-item command="myPage"
                ><a-icon type="home" />Settings</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <span style="padding: 8px">
            <!-- <a-badge dot>{{ $store.getters.full_name }}</a-badge> -->
            <a-badge>{{ $store.getters.full_name }}</a-badge>
          </span>
        </span>
      </a-layout-header>
      <a-layout-content style="padding: 10px">
        <router-view />
      </a-layout-content>
    </a-layout>
    <el-dialog
      title="設定"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      top="5%"
      width="750px"
    >
      <Settings @cancel="closeDialog" />
    </el-dialog>
  </a-layout>
</template>

<script>
import Settings from "@/views/project/Settings";
export default {
  name: "index",
  components: { Settings },
  data() {
    return {
      collapsed: false,
      dialogVisible: false,
      list: [
        {
          key: "1.1",
          title: "案件一覧",
          url: "ProjectList",
          icon: "fire"
        },
        {
          key: "1.2",
          title: "見積一覧",
          url: "EstimateList",
          icon: "solution"
        },
        {
          key: "1.3",
          title: "請求一覧",
          url: "InvoiceList",
          icon: "transaction"
        },
        //   ],
        // },
        {
          key: "2",
          title: "得意先",
          url: "CustomerList",
          icon: "idcard"
        },
        {
          key: "3",
          title: "商品",
          url: "ProductList",
          icon: "barcode"
        },
        {
          key: "4",
          title: "カテゴリー",
          url: "CategoryList",
          icon: "copyright"
        }
        // {
        //   key: "5",
        //   title: "設定",
        //   url: "Settings",
        //   icon: "setting"
        // }
      ]
    };
  },
  computed: {
    changeLogo() {
      return this.collapsed ? "logosimp" : "logo";
    }
  },
  methods: {
    toMenu(Menu) {
      this.$router.push({ path: Menu });
    },
    handleCommand(command) {
      switch (command) {
        case "logOut":
          this.$store.commit("deleteToken");
          this.$router.push({ path: "SignIn" });
          break;
        case "myPage":
          this.dialogVisible = true;
          break;
        default:
          break;
      }
    },
    closeDialog() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.ant-menu {
  background-color: rgba(255, 255, 255, 0);
}
.ant-layout-sider {
  background-color: rgba(138, 151, 123, 0.4);
}
.ant-layout-header {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0;
}
.ant-layout {
  position: relative;
  min-width: 0;
  /* background: #001529; */
  background: #fff;
  background-image: url("../assets/img/5626873.jpg");
  transition: all 0.2s;
}
/* .ant-menu-inline .ant-menu-item:after {
  border-right: #fff;
} */
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(138, 151, 123, 0.1);
}

#components-layout-demo-custom-trigger {
  min-height: 100%;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  background-image: url("../assets/img/logo.png");
  margin: 16px;
}
#components-layout-demo-custom-trigger .logosimp {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  background-image: url("../assets/img/logosimp.png");
  background-repeat: no-repeat;
  margin: 16px 24px;
}
#components-layout-demo-custom-trigger .user {
  position: absolute;
  right: 30px;
}
</style>
